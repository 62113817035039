import React from "react";
import * as style from './style.module.scss'

export default function index() {
  return (
    <div className={style.allinone}>
      <h1>All in one</h1>
      <p>
      All your graphic and communication services in one place to help coordinate your pitch effort and promote greater use and consistency. 
      </p>
    </div>
  );
}
