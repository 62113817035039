export const posts = [
  {
    img: "services_post1.webp",
    title: "Onboarding programs",
    desc: "You can use your existing presentation material, directly integrating it into MASTER WiZR. Our onboarding program create optimal results...",
    url: "#",
  },
  {
    img: "services_post2.webp",
    title: "Graphic and UX Design Services",
    desc: "Graphic work, logo design, reformatting, all included",
    url: "#",
  },
  {
    img: "contact2.webp",
    title: "Follow-up programs",
    desc: "MASTER WIZR is an intelligent business pitch tool that gets smarter over time. The more you use it the less you need to worry about anyone dropping the ball / consistency and follow-through.",
    url: "#",
  },
  {
    img: "services_post4.webp",
    title: "Video Debriefs",
    desc: "With an archive of over 1500 Video Debriefs created for you, we have extensive experience in digitizing and easing communication through the...",
    url: "#",
  },
  {
    img: "services_post5.webp",
    title: "CSR",
    desc: "MASTER WiZR has two overall objectives as a business-to-business solution for a broad range of users...",
    url: "#",
  },
  {
    img: "services_post6.webp",
    title: "Owners",
    desc: "Master WiZR is owned by Core20 Consulting Inc and Acini As, established in Oslo, Norway. Core20 has worked on thousands of...",
    url: "#",
  },
];
