import React, { useState, useEffect } from "react";
import * as styles from "./styles.module.scss";
import AllInOne from "../../../atoms/allInOne";
import Card from "../../../atoms/serviceCard";
import SwipeableViews from "react-swipeable-views";
import Pagination from "../../../atoms/Pagination";
import { posts } from "../../../../constants/services_essentials";
import StyledButton from '../../../atoms/StyledButton/index';
export default function Index() {
  const [width, setWidth] = useState(0);
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const mobileView = () => {
    return (
      <div style={{display:'grid'}}>
        <SwipeableViews  index={index} onChangeIndex={handleChangeIndex}>
          {posts.map((res) => {
            return (
              <Card
                key={res.title}
                img={res.img}
                title={res.title}
                desc={res.desc}
                url={res.url}
              />
            );
          })}
        </SwipeableViews>
        <Pagination dots={4} index={index} />
      </div>
    );
  };
  const desktopView = () => {
    return (
      <div className={styles.row}>
        {posts.map((res) => {
          return (
            <Card
              key={res.title}
              img={res.img}
              title={res.title}
              desc={res.desc}
              url={res.url}
            />
          );
        })}
      </div>
    );
  };
  return (
    <div className={styles.content}>
      <AllInOne />

      <div className={styles.cardContent}>
        <h1>Services & Essentials</h1>
        {width && width < 992 ? mobileView() : desktopView()}
        <StyledButton title="Get In Touch" extraClass='orangeGray' icon  link='/contact'/>
      </div>
    </div>
  );
}