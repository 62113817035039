import React,{useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from '../components/atoms/header';
import Banner from '../components/molecules/Services&Info/banner';
import ServicesPart from '../components/molecules/Services&Info/services';
import FreeTrial from "../components/atoms/freeTrial";
import Head from "../components/ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
function Services() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 1500
    });
  }, []);
    return (
      <>
        <Head
        title="Services & Info"
        description="All in one
        All your graphic and communication services in one place to help coordinate your pitch effort and promote greater use and consistency."
        ogTitle="Services & Info"
      />
        <main>
          <Header />
          <Banner />
          <ServicesPart />
          <FreeTrial />
          <Footer/>
      </main>
      </>
    );
}

export default Services;