import React from "react";
import HeroBanner from "../../../atoms/HeroBanner";
import * as styles from './styles.module.scss';
import StyledButton from '../../../atoms/StyledButton';
import {IoIosArrowForward} from 'react-icons/io';
function index() {
  return (
    <div >
      <HeroBanner filename="22.jpg" extraClass="servicesBanner">
        <div className={styles.bannerModules}>
          <p data-aos="zoom-in" data-aos-delay="100">All you need is here.</p>
          <h2 data-aos="zoom-in" data-aos-delay="200">Services & Info</h2>
          <div data-aos="flip-down" data-aos-delay="100">
           <StyledButton title="Try now"
                extraClass="whiteBtn"
                icon={<IoIosArrowForward />}
                link="https://beta-studio.mwstream.com/control-panel/cover-pages"
             email={true}
                />
                
                </div>
          
        </div>
      </HeroBanner>
    </div>
  );
}

export default index;
